import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import { HashRouter,Route, Switch,Redirect } from 'react-router-dom';
import { Router } from 'react-router-dom';
import Login from './componants/Login/';
import Home from "./componants/Home";
import Register from './componants/Login/Register';
import Upload from "./componants/Upload";
import Search from "./componants/Search";
import { createBrowserHistory } from 'history'

const customHistory = createBrowserHistory();

const Root = () => {
  return (
    <HashRouter history={customHistory}>
      <Switch>
        <Route exact={true} path="/" component={Login} />
        <Route exact path="/register" component={Register}/>
        <Route path="/" component={Home}/>
        <Route path="*" component={Home} />
        <Redirect from="/login" to="/" />
      </Switch>
    </HashRouter>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
