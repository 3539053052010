
import environment from "../environment/environment";

export async function PostData(type, userData) {
    let BaseUrl = environment.REACT_APP_API_URL + "/api/";
    //let BaseUrl = "/api/";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    //myHeaders.append("Authorization", 'Bearer ' + userData.token);

    // console.log("tokan", JSON.stringify(userData) );

    return new  Promise((resolver,reject)=>{
        fetch(BaseUrl+type,{
             method: 'POST',
             headers: myHeaders,
             body:JSON.stringify(userData),
             redirect: 'follow'
             })
            .then((response) => response.json())
            .then((responseJson)=>{
                resolver(responseJson);
            })
            .catch((error)=>{
                reject(error);
               
                console.log("error msg",error);
                
            })
       
    });
}