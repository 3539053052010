import React, { useEffect, useState}from 'react'
import {  Row, Col, } from "react-bootstrap";
import { Card, CardContent, Typography, CardHeader, Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { PostData } from "../services/PostData";
import { Pie, Bar, Line, Doughnut} from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { createBrowserHistory } from 'history'

const customHistory = createBrowserHistory();
let lOutBalVal = [];
let lOutBalColor =[];
let lOutBalName = [];
let disbusAmtYr =[]
let lonamp = []
let disbalname =[]
let lonoutperVal = []
export const Dashboard = ({ modalVisibleclick, modalOpen, expireIn}) => {

    const [data, setdata] = useState({
        token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id'),
       })
       const [totalAc, settotalAc] = useState("")
       const [loanAmt, setloanAmt] = useState("")
       const [outBal, setoutBal] = useState("")
       const [defaulter, setdefaulter] = useState("")
       const [securedAmt, setsecuredAmt] = useState("")
       const [nonSecuredAmt, setnonsecuredAmt] = useState("")
       const [disb, setdisb] = useState([])
       const [disbmon, setdisbmon] = useState([])
       const [subcribePlan, setsubcribePlan] = useState([])
       const [lonout, setlonout] = useState()
       const [lonoutper, setlonoutper] = useState([])
       const [npadata, setnpadata] = useState([])
     
    const classes = useStyles();

        const [open, setOpen] = React.useState();

        // const handleOpen = () => {
        //     setOpen(true);
        // };

      const  onClose = () => {
            // console.log("modal close ", modalVisibleclick);
            setOpen(!open);
            modalVisibleclick(false)
        }

        // const handleClose = () => {
        //     setOpen(false);
        //     props.closeM(false)
        // };


    // useEffect(() => {
    //     if (data.token == null) {
    //         console.log("redirect to login");
    //         return <Redirect to="/" />
    //     }
    // }, [])

useEffect(()=>{
    //console.log("data ",data);
    if (data.token == null) {
        // console.log("redirect to login");
       
    }else{ 
    PostData('admin/defaulteranalysis', data)
        .then((result) => {
            let responseJSON = result;
           // console.log("result", responseJSON);
            if (responseJSON.status="Success"){
                
                settotalAc(responseJSON.data.dashboard_data.total_account)
                setloanAmt(responseJSON.data.dashboard_data.loan_amount)
                setoutBal(responseJSON.data.dashboard_data.outstanding_balance)
                setdefaulter(responseJSON.data.dashboard_data.defaulter)
                setsecuredAmt(responseJSON.data.dashboard_data.secured_amount)
                setnonsecuredAmt(responseJSON.data.dashboard_data.non_secured_amount)
                
                lOutBalVal.push(Object.values(responseJSON.data.loan_outstanding_pie_data))
                lOutBalName.push(Object.keys(responseJSON.data.loan_outstanding_pie_data))
                setlonout(lOutBalVal[0].reduce((a, b) => a + b))
                // console.log("pie 1", lOutBalVal[0].reduce((a, b) => a + b));
                setdisb(responseJSON.data.year_wise_data)
                setdisbmon(responseJSON.data.month_wise_data)
                setlonoutper(lonoutperVal)
                lonoutperVal.push(Object.values(responseJSON.data.loan_oustanding_percentage_data))
                
                setnpadata(Object.values(responseJSON.data.npatype_wise_data))
                // console.log("pie 2", lonoutper);
            }
            

        })
    }

}, []);

useEffect(() => {
    if (data.token == null) {
        // console.log("redirect to login");

    } else { 
    PostData('admin/subscriptionplan', data)
        .then((result) => {
            let responseJSON = result;
            // console.log("result", responseJSON);
            if (responseJSON.status = "Success") {
                setsubcribePlan(responseJSON.data)

            }
        })
    }
}, [])
    // console.log("loutbal", lOutBalVal);
    // console.log("loutbal", lOutBalName);
    // console.log("disbusAmtYr", disb);
    //  console.log("disbmon", disbmon);
    // console.log("lonout per",lonoutper);
    // console.log("npa data",npadata);

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    var promiseOutbalColor = new Promise((resolve,reject)=>{
         for(var i =0;i<=lOutBalVal.length;i++){
             lOutBalColor.push(getRandomColor())
         } 
    })
    promiseOutbalColor.then(result => {
        
    }, function (error) {

    });
   

    var promise = new Promise((resolve, reject) => {
        //  console.log("loanoutper ", lonoutper);
        var res = lonoutper[0].map(v => parseFloat(v, 10));
        // console.log("res ", res);
         disbalname = lOutBalName[0].map(name => name.replace("_", " ").toUpperCase());
       
        if (!Array.isArray(res)) return;
            let sum = 0;
            res.forEach(each => {
                sum += each;
            });
       
        var resp = res.map(v => 
            
             //Math.round(v/sum*100) 
            (v / sum * 100).toFixed(2)
            );
        // console.log("filter loanoutper ", res);
        //  console.log("resp ", resp);
        resolve(resp)
        
    });
    promise.then( result => {
        // console.log("loanoutper total", result);
        lonamp = result
        
        // console.log("filter loanoutper pp ", lonamp);
        

 }, function(error) {

 });

    
    
    // console.log("pie 1", lOutBalVal[0]);
    // console.log("pie 2", lonamp);
    
    
    const linechartdata = {
        labels: disb.map((data) => (data.disbursement_year)),
        datasets: [
            {
                label: 'Years',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: disb.map((data) => (data.disbursement_amount))
            }
        ]
    };
    const barchartdata ={
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug','Sept','Oct','Nov','Dec'],
        datasets: [{
            label: 'Months',
            data: disbmon.map((data) => (data.disbursement_amount)),
            backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a', '#3f51b5', '#f44336', 
                '#ffc107', '#795548', '#9c27b0', '#b388ff', '#ffb74d', '#616161'
            ],
            hoverBackgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a', '#3f51b5', '#f44336',
                '#ffc107', '#795548', '#9c27b0', '#b388ff', '#ffb74d', '#616161'
            ]
        }]
    }


   
   const pichartdata = {
       labels: disbalname,
       
        datasets: [{
            data: lOutBalVal[0],
            backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a',  "#0d5ac1",
                "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0", "#f205e6", "#1c0365", 
                "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00", '#3f51b5',
                "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e", '#f44336'
            ],
           
        }],
       
    };

    const pichartdataper = {
        labels: disbalname,
        datasets: [{
            data: lonamp,
            // backgroundColor: lOutBalColor,
            backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a',  "#0d5ac1",
                "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0", "#f205e6", "#1c0365", 
                "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00", '#3f51b5',
                "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e", '#f44336', 
            ],
            // hoverBackgroundColor: [
            //     '#FF6384',
            //     '#36A2EB',
            //     '#FFCE56'
            // ]
        }]
    };

    const barchartnpadata = {
        labels: ['Standard', 'Sub Standard', 'DoubtFul A', 'DoubtFul B', 'DoubtFul C', 'Loss Asset'],
        datasets: [{
            label: 'Standard',
            data: npadata,
            backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a', '#3f51b5', '#f44336'
            ],
            hoverBackgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#66bb6a', '#3f51b5', '#f44336'
            ]
        }]
    }

    var options = {
        responsive: true,
        maintainAspectRatio: true,
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0
    };
    
    const optionsPie = {
        animation: {
            duration: 0
        },
        legend: {
            display: false,
        }
    }
    
    return (
        <div>
            <div className="pageTitle primaryColor"> Dashboard
           
            </div>
            <div className="container-fluid mt-5">
                <Row>
                    <Col md={6} lg={3} xm={12}>
                        <Card className="card border-left-primary ">
                            <CardContent>
                                <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                    Total Loan Amount
                                 </Typography>
                                <Typography variant="h5" component="h2">
                                    <NumberFormat value={loanAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col md={6} lg={3} xm={12}>
                        <Card className="card border-left-success ">
                            <CardContent>
                                <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                    Outstanding Balance
                                 </Typography>
                                <Typography variant="h5" component="h2">
                                    <NumberFormat value={outBal} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col md={6} lg={3} xm={12}>
                    <Card className="card border-left-info ">
                            <CardContent>
                                <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                    Secured Amount
                                 </Typography>
                                <Typography variant="h5" component="h2">
                                    <NumberFormat value={securedAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col md={6} lg={3} xm={12}>
                        <Card className="card border-left-primary ">
                        <CardContent>
                            <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                Non-Secured Amount
                                 </Typography>
                            <Typography variant="h5" component="h2">
                                
                                <NumberFormat value={nonSecuredAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                            </Typography>
                        </CardContent>
                    </Card>
                    </Col>
                    <Col md={6} lg={3} xm={12}>
                        <Card className="card border-left-success ">
                            <CardContent>
                                <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                    Total No. of Accounts
                                 </Typography>
                                <Typography variant="h5" component="h2">
                        
                                    <NumberFormat value={totalAc} displayType={'text'} thousandSeparator={true}  />

                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                      <Col md={6} lg={3} xm={12}>
                        <Card className="card border-left-warning ">
                           
                            <CardContent>
                                <Typography className="cardTitle" color="textSecondary" gutterBottom>
                                    Total No. of Defaulters 
                                 </Typography>
                                <Typography variant="h5" component="h2">
                                    <NumberFormat value={defaulter} displayType={'text'} thousandSeparator={true} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} >
                        <Card className="card  ">
                            <CardHeader
                                title="Total Loan Outstanding Balance"
                                // subheader="September 14, 2016"
                            />
                            <CardContent>
                               
                                {lonout > 0 ? 
                                <Pie data={pichartdata} options={optionsPie}  />
                                 : 'No Data Available'}
                            </CardContent>
                            
                        </Card>
                    </Col>
                    <Col md={6} sm={12} >
                        <Card className="card  ">
                            <CardHeader
                                title="Total Loan Outstanding Balance in %"
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                {lonamp.length > 0 ? 
                                <Doughnut data={pichartdataper} options={optionsPie} />
                                : 'No Data Available'}
                            </CardContent>

                        </Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card className="card  ">
                            <CardHeader
                                title="Year Wise Loan Disbursement Analysis"
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                {disb.length > 0 ?
                                <Line data={linechartdata} options={options} redraw/>
                                : 'No Data Available'}
                            </CardContent>
                        </Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card className="card  ">
                            <CardHeader
                                title="Month Wise Loan Disbursement Analysis"
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                {disbmon.length > 0 ?  
                                <Bar data={barchartdata} options={options} redraw/>
                                :'No Data Available'}
                            </CardContent>
                        </Card>
                    </Col>
                    <Col md={6} sm={12}>
                        <Card className="card  ">
                            <CardHeader
                                title="NPA Type Wise Loan Amount Analysis"
                            // subheader="September 14, 2016"
                            />
                            <CardContent>
                                {npadata.length >0 ? 
                                <Bar data={barchartnpadata} options={options} redraw />
                                :'No Data Available'}
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    className={classes.modal}
                    open={modalOpen}
                    onClose={onClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalOpen}>
                        <div className={classes.paper}>
                            <div className={classes.header}>
                            <div > Subscription Plan  </div>
                            <Button color="default" onClick={onClose} > Close</Button>
                            </div>
                            <div className={classes.body}> 
                                <Typography> Select any subscription plan for unlimited acces.</Typography>
                                <span className="primaryColor"> Your current plan expire in {expireIn} days.</span>
                                <br /><br />
                               
                               {
                                   subcribePlan.map((plan,i)=>{
                                       return(
                                       
                                           <div key={plan.id} style={{borderBottom:1,borderBottomStyle:'dashed',textTransform:'capitalize',padding:'5px 10px'}}> 
                                               {plan.subscription_type} Plan Rs.{plan.charges} / {plan.validity} days
                                               {/* [{plan.description}] */}
                                           </div>
                                          
                                       
                                       )
                                   })

                               }

                                <div style={{ display: 'flex' }}>
                                    {/* <img src={Qrcode} className={classes.qr}/> */}
                                  
                                    <div className="p-3" > 
                                        <h5 className="primaryColor"> Payment for activation </h5>
                                       
                                        <h6>  Bank  : Bank of India </h6>
                                        <h6>  Name : Amigoestech </h6>
                                        <h6>  AC No : 875820110000427 </h6>
                                        <h6>  IFSC : BKID0008758 </h6>
                                        <h6>GSTIN/UIN 27ASIPC4657K1ZG</h6>
                                        <hr/>
                                        <h5>Call @ 7447571201 / 9730015177 </h5>
                                    </div>
                               </div>
                               
                            </div>

                        </div>
                    </Fade>
                </Modal>
            </div>
            
            
        </div>
    )
}
  
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        width:'450px',
        height: '100 %'
       
    },
    header:{
        backgroundColor: "#FF6696", 
        color: "#fff", 
        padding: "0.9rem", 
        fontSize: "1.2rem",
        justifyContent:'space-between',
        display: 'flex'
    },
    body:{
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
         height: '450px',
    },
    button:{
        width:'100%',
        marginBottom:'10px'
    },
    qr:{
        width: '150px'
    }
}));