import React, { Component } from 'react'
import { Container,  Button } from "react-bootstrap";
import { } from './home.css';
import { Typography, Drawer, List, ListItem, ListItemText, Avatar, Divider, } from '@material-ui/core';
import { AiOutlineDashboard, AiOutlineCloudUpload, AiOutlineSetting, AiOutlinePoweroff} from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { Dashboard } from './Dashboard';
import Upload from "../Upload";
import Search from "../Search";
import { PostData } from '../services/PostData';
import { HashRouter,Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history'
import { Switch } from 'react-router';
import Login from '../Login';
const customHistory = createBrowserHistory();
 const drawerWidth = 256;
export class index extends Component {
    constructor(props){
        super(props)
        this.state={
            userName:"",
            selectedIndex: localStorage.getItem('index'),
            service_type:"",
            expire_in:"",
            open:false,
            registration_no: ""
        }
       
        
        
    }
    componentDidMount(){
        localStorage.setItem('index', 'dashboard')
        var token = localStorage.getItem('token');
        var user_id = localStorage.getItem('user_id')
        var data = { "token": token, "user_id": user_id}
        // console.log("data",data);

        if (token == null ) {
            // console.log("redirect to login");
            this.props.history.push('/')
        }
        else{
        //console.log("selectedIndex", this.state.selectedIndex);
        PostData('admin/userdetails', data).then((result) => {
            let responseJSON = result;
           
            if (responseJSON.status == "SUCCESS"){
                this.setState({
                    userName: responseJSON.user_data.bank_society_name,
                    service_type: responseJSON.user_data.service_type,
                    expire_in: responseJSON.user_data.plan_remaining_days,
                    registration_no: responseJSON.user_data.registration_no

                })
            }
            else{
                if (responseJSON.message == "User Not found"){
                    // console.log("User Not found");
                    localStorage.clear();
                    this.props.history.push('/')
                }
               else{
                    // console.log("error in fetching data");
               }
            }
        
             
 
        })
        .catch((error) => {
        //    console.log("error msg", error);
            

         })
        }
    }

     handleOpen = () => {
         this.setState({ open: true});
        // console.log("subscrive",this.state.open);
        
    };

    //  handleClose = (isClose) => {
    //      this.setState({ open: isClose });
    // };
    handleClose = (value) => {
        // console.log("modal value", value);
        this.setState({ open:value})
    }
    // componentWillMount(){
    //     if (localStorage.getItem("token")) {
           
    //         this.props.history.push('/dashboard')
    //         return;
    //     }else{
    //         this.props.history.push('/')
    //     }
    // }

    logout=(event, index)=>{
        this.setState({ selectedIndex: index })
        localStorage.getItem("token",'')
        localStorage.getItem("user_id", '')
        localStorage.setItem('index', '')
        localStorage.clear();
        this.props.history.push('/')
    }

    handleListItemClick = (event, index) => {
        localStorage.setItem('index',index)
        this.setState({ selectedIndex: index})
        // console.log("selectedIndex", this.state.selectedIndex);
    };

    render() {
        const { service_type,userName,open,expire_in}=this.state;

       
        return (
          
            <Container fluid="true">
               
                <div style={{ display: 'flex', height: '100%'}}>
                    <HashRouter history={customHistory}>    
                        <Drawer
                            variant="permanent"
                            className="drawer">
                            <div className="sideBar" >
                             <div className="logoTitle">SOCITY PORTAL</div>
                            <Divider className="borderWhite"/>
                            <div className="sideWrap"> 
                            <div className="userDetails">
                                    <Avatar alt="User"  />
                                <div className="accountName">Hello, {userName}</div>
                                <div>Registration No : {this.state.registration_no} </div>
                                    <div className="subscType">Your Plan : <span className="primaryColor" style={{textTransform:'capitalize'}}> {service_type}</span></div>
                                    <div className="subscType"> Service Expire In :<span className="primaryColor"> {expire_in}</span>  days</div>
                                    <Button className="upgradeBtn" onClick={this.handleOpen}>Upgrade Plan + </Button>
                                    
                            </div>
                                <div className="listWrapp"> 
                                    <List style={{marginTop:'5%',}}>
                                        <Typography className="menuTitle">Menu</Typography>
                                        <ListItem
                                            button
                                            component={Link} to="/dashboard"
                                                selected={this.state.selectedIndex === 'dashboard'}
                                                onClick={(event) => this.handleListItemClick(event, 'dashboard')}>
                                                <AiOutlineDashboard className="primaryColor mr10"/> 
                                                <ListItemText className="menuHed" primary="Dashboard" />
                                        </ListItem>
                                        <ListItem
                                            disabled={expire_in === "expired" ? true : false}
                                            button
                                            component={Link} to="/search"
                                            selected={this.state.selectedIndex === 'search'}
                                            onClick={(event) => this.handleListItemClick(event, 'search')}>
                                                <FiSearch className="primaryColor mr10"/>
                                            <ListItemText className="menuHed" primary="Search Defaulters" />
                                        </ListItem>
                                        <ListItem
                                            disabled={expire_in === "expired" ? true : false}
                                            button
                                            component={Link} to="/upload"
                                            selected={this.state.selectedIndex === 'upload'}
                                            onClick={(event) => this.handleListItemClick(event,'upload')}>
                                                <AiOutlineCloudUpload className="primaryColor mr10"/>
                                            <ListItemText className="menuHed" primary="Upload Data" />
                                        </ListItem>
                                    </List>
                                    <List style={styles.bottomMenu}>
                                    {/* <ListItem
                                        button
                                        selected={this.state.selectedIndex === 3}
                                        onClick={(event) => this.handleListItemClick(event,3)}>
                                            <AiOutlineSetting className="primaryColor mr10"/>
                                            <ListItemText className="menuHed" primary="Setting" />
                                    </ListItem> */}
                                    <ListItem
                                        button
                                        // selected={this.state.selectedIndex === 4}
                                            onClick={(event) => this.logout(event,'logout')}>
                                            <AiOutlinePoweroff className="primaryColor mr10"/>
                                            <ListItemText className="menuHed" primary="Log Out" />
                                    </ListItem>
                                </List>
                                </div>
                            </div>
                            </div>

                           
                        </Drawer>
                    {/* <div style={styles.appBarShift} className="appBarShift" key={this.state.selectedIndex}>
                        {this.state.selectedIndex === 0 && <Dashboard open={open} key={this.state.selectedIndex} closeM={this.handleClose} expireIn={expire_in}/>}
                        {this.state.selectedIndex === 1 && <Search key={this.state.selectedIndex} />}
                        {this.state.selectedIndex === 2 && <Upload key={this.state.selectedIndex}/>}
                        </div>
                    </div> */}
                <div style={{display:'none'}}> 
                            <Dashboard key={this.state.selectedIndex} modalVisibleclick={this.handleClose} modalOpen={open} expireIn={expire_in} history />

                </div>
                        <div style={styles.appBarShift} className="appBarShift" key={this.state.selectedIndex}>
                        <Switch>
                            <Route exact path="/" component={Dashboard} />
                            {/* <Route path="/" component={Dashboard} /> */}
                            {expire_in === "expired" ?'':
                                <Route exact path="/search" component={Search} /> 
                            }
                            {expire_in === "expired" ?'':
                                    <Route exact path="/upload" component={Upload} />
                            }
                           
                        
                            <Route exact path="*" component={Dashboard} />

                            {/* <Route exact path="/login" component={Login} /> */}
                        </Switch>
                     </div>
            </HashRouter>
                
         </div>
            </Container>
        )
    }
}

export default index

const styles ={
    root: {
        display: 'flex',
    },
   
    // appBarShift: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     paddingLeft: '1.5rem',
    //     paddingRight: '1.5rem',
    //     marginBottom: '1.5rem',
    //     marginTop: '1.5rem'
    // },
   
    hide: {
        display: 'none',
    },
   
    drawerOpen: {
        width: drawerWidth,
        
    },
    drawerClose: {
        
        overflowX: 'hidden',
       
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',        
    },
    bottomMenu: {
        top: '20%'
    },
}