import React, { useState} from 'react'
import { Row, Col, Alert,  Button, Spinner } from "react-bootstrap";
import { TextField,  FormControlLabel, Checkbox, Paper, Table, 
    TableBody, TableContainer, TableCell, TableHead, TablePagination, TableRow,
    Modal,Backdrop,Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiSearch, FiPrinter, FiXCircle} from "react-icons/fi";
import { PostData } from "../services/PostData";
import { DownloadData } from "../services/DownloadData";
import { ToastContainer, toast } from 'react-toastify';

const inquery =[
    { id: 'name', label: 'Full Name', minWidth: 170 },
    { id: 'name', label: 'Bank Name', minWidth: 170 },
    { id: 'name', label: 'Bank Address', minWidth: 170 },
    { id: 'name', label: 'Bank Official No.', minWidth: 170 },
    { id: 'name', label: 'Bank Email.', minWidth: 170 },
]

const columns = [
    { id: 'action', label: 'Action', minWidth: 100 },
    { id: 'name', label: 'Account Name', minWidth: 160 },
    {
        id: 'bank_name',
        label: 'Bank Name',
        minWidth: 180,
        align: 'left',
    },
    {
        id: 'is_defaulter',
        label: 'Defaulter',
        minWidth: 110,
        align: 'center',
        format: (value) => value.toLocaleString()
    },
    
    { id: 'pan_no', 
    label: 'PAN', 
    minWidth: 100 },
    { id: 'adhar_no', label: 'Adhar', minWidth: 100 },
    { id: 'voter_no', label: 'Voter', minWidth: 100 },
    { id: 'guarantor1_name', label: 'Gntr 1', minWidth: 100 },
    { id: 'guarantor2_name', label: 'Gntr 2', minWidth: 100 },
   
    
];
const columnsModal = [
    { id: 'name', label: 'Customer Name', minWidth: 170 },
    { id: 'account_number', label: 'Account Number', minWidth: 170 },
    { id: 'pan_no', label: 'PAN No', minWidth: 100 },
    { id: 'adhar_no', label: 'Aadhar No', minWidth: 100 },
    { id: 'voter_no', label: 'Voter No', minWidth: 100 },
    { id: 'bank_name', label: 'Bank Name', minWidth: 100 },
    { id: 'bank_address', label: 'Bank Address', minWidth: 100 },
    { id: 'is_defaulter', label: 'Is Defaulter', minWidth: 100 },
    { id: 'reg_no', label: 'Registration No', minWidth: 100 },
    { id: 'account_open_date', label: 'Account Open Date', minWidth: 100 },
    { id: 'loan_type', label: 'Loan Type', minWidth: 100 },
    { id: 'loan_period', label: 'Loan Period', minWidth: 100 },
    { id: 'loan_expiry_date', label: 'Loan Expiry Date', minWidth: 100 },
    { id: 'installment_amount', label: 'Installment Amount', minWidth: 100 },
    { id: 'over_due_no_installment', label: 'Overdue Installment', minWidth: 100 },

]
const columnsModal2 = [
    { id: 'mobile', label: 'Mobile No', minWidth: 170 },
    { id: 'email', label: 'Email Id', minWidth: 170 },
    { id: 'address', label: 'Address', minWidth: 100 },
    { id: 'pincode', label: 'Pin Code', minWidth: 100 },
    { id: 'city', label: 'City', minWidth: 100 },
    { id: 'state', label: 'State', minWidth: 100 },
    { id: 'country', label: 'Country', minWidth: 100 },
    { id: 'office_no', label: 'Office No', minWidth: 100 },
    { id: 'is_npa', label: 'Is NPA', minWidth: 100 },
    { id: 'loan_amount', label: 'Loan Amount', minWidth: 100 },
    { id: 'disbursement_amount', label: 'Disbursement Amount', minWidth: 100 },
    { id: 'disbursement_date', label: 'Disbursement Date', minWidth: 100 },
    { id: 'loan_outstanding_balance', label: 'Loan Outstanding Balance', minWidth: 100 },
    { id: 'last_installment_paid_date', label: 'Last Installment Paid Date', minWidth: 100 },
    { id: 'npa_type', label: 'NPA Type', minWidth: 100 },
    { id: 'is_secured', label: 'Is Secured', minWidth: 100 },
    { id: 'guarantor1_name', label: 'Guarantor 1 Name', minWidth: 100 },
    { id: 'guarantor1_address', label: 'Guarantor 1 Address', minWidth: 100 },
    { id: 'guarantor2_name', label: 'Guarantor 2 Name', minWidth: 100 },
    { id: 'guarantor2_address', label: 'Guarantor 2 Address', minWidth: 100 }

]


function Index(){

    const classes = useStyles();
    const [resultShow, setresultShow] = useState(false)
    const [selectedValue, setSelectedValue] = useState(false);
    const [isShowing, setisShowing] = useState(false)
    const [rows, setrows] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [request, setrequest] = useState()
    const [open, setOpen] = React.useState(false);
    const [modalData, setmodalData] = useState([])
    const [searchCount, setsearchCount] = useState()
    const [progress, setprogress] = useState(false)
    const [progressd, setprogressd] = useState(false)
    const [file, setfile] = useState("")
    const [download, setdownload] = useState({})
    const notifyError = () => toast.error("Please enter keyword properly", { autoClose: 3000 });
    const cusnotifyError = (error) => toast.error(error, { autoClose: 3000 });

    const handleOpen = (event) => {
        setOpen(true);
        setmodalData(event)
        //console.log("modalData", event.defaulter_id);
        setdownload({
            token: localStorage.getItem('token'),
            user_id: localStorage.getItem('user_id'),
            customer_id: event.defaulter_id,
            name: event.name
        })
        
    };

    const handleClose = () => {
        setOpen(false);
        setprogressd(false);
    };

    const handleDownload = () => {
        setprogressd(true)
        // console.log("download", download);
            
        DownloadData("admin/customerpdf", download)
          // 1. Convert the data into 'blob'
          .then((blob) => {
            setprogressd(false);
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${download.name}.pdf`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            // console.log("error msg", error);
          });
    
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [data, setdata] = useState({
        // defaulter_name:"",
        // mobile_no:"",
        // voter_no:"",
        // pan_no:"",
        // adhar_number:"",
        // guarantor_name:"",
        token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id'),
    })
    const searchRequest = async () => {
       
        if (Object.keys(data).length < 3) {
            
            return notifyError(); 
        } 
        else {
        // console.log("data",data);
        // console.log("request",request);
        
        
      
        setprogress(true)
        PostData("admin/searchdefaulter", data)
          .then((result) => {
            let responseJSON = result;
            // console.log(responseJSON);

            if (responseJSON.status === "SUCCESS") {
              setprogress(false);
              setrows(responseJSON.data.search_result);
              setsearchCount(responseJSON.data.result_count);
              setresultShow(true);
            } else {
              cusnotifyError(responseJSON.message);
                             
            }
          })
          .catch((error) => {
            setprogress(false);
            cusnotifyError("Check internet connectivity \n" + error);
            // console.log("search error msg ", error);
          });
        }

    } 
    const handleKeyPress = event => {
        if (event.key == 'Enter') {
           
                searchRequest();

        }
    };
    const onChange=async(e)=>{
        
        if ( e.target.value ) {
            setdata({
                ...data,
                [e.target.name]: e.target.value
            })
        } else {
            delete data[e.target.name];
            setdata({
                ...data
            })
        }

    }
    const handleChange = (event) => {
        setSelectedValue(event.target.checked);
        setisShowing(event.target.checked)
    };
    const handleClick =(e)=>{
        Alert("hello");
    }
    
    const secureText = (str) => {
        let length = (str.length / 2) + 1
        var res = str.substr(0, length);
        var res2 = str.substr(6, 4);
        res2 = res2.replace(res2, "XXXX");
        return res + res2;
    }
    const secureVoter = (str) => {
        let strlength = ""
        let stringvot="";

            if (str.length > 5) {
                strlength = (str.length / 2) + 1
                var res = str.substr(0, strlength);
                var res2 = str.substr(7, 4);
                res2 = res2.replace(res2, "XXXX");
                stringvot = res + res2;
            } else {
                stringvot = str;
            }

        return stringvot;
    }
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <div className="searchHeader">
                {/* <Button className="primaryBgColor"> <FiSearch className="searchIcon"/> </Button> */}
                <div className="titleWrapp">
                    <div className="searchTitle primaryColor"> Search Loan Defaulters</div>
                    <div className="searchSubTitle lightColor cardTitle" color="textSecondary" > find the loan defaulters here </div>

                </div>
              
            </div>
            
            <div className="container " style={{marginTop:'2%'}}>
                <Row className="container">
                    <Col md={12}> <FiSearch style={{ fontSize: '1.8rem', color: '#ee6395', marginTop: '20px', marginRight: '10px'}}/></Col>
                    <Col sm={8} md={6} xsm={12} style={{ display:'flex',alignItems:'center'}}>
                        
                        <TextField 
                            name="defaulter_name" label="Customer Name" className="inputBox" onKeyPress={handleKeyPress} onChange={onChange} helperText="" />
                    </Col>
                    {isShowing && 
                    <Col sm={4} md={3} xsm={12} >
                        <TextField name="pan_no" label="Pan No" className="inputBox" onChange={onChange} onKeyPress={handleKeyPress} />
                    </Col>
                    }
                    {isShowing && 
                    <Col sm={4} md={4} xsm={12} className="ml5*">
                        <TextField type="number" name="adhar_number" label="Aadhar Number" className="inputBox" onChange={onChange} onKeyPress={handleKeyPress} />
                    </Col>
                    }
                    {isShowing && 
                    <Col sm={4} md={3} xsm={12}>
                        <TextField name="voter_no" label="Voter No" className="inputBox" onChange={onChange} onKeyPress={handleKeyPress} />
                    </Col>
                    }
                    {isShowing && 
                    <Col sm={4} md={3} xsm={12}>
                        <TextField name="mobile_no" type="number" label="Mobile No" className="inputBox" onChange={onChange} onKeyPress={handleKeyPress} />
                    </Col>
                   }
                    {isShowing &&
                        <Col sm={4} md={4} xsm={12} className="ml5*">
                        <TextField name="guarantor_name" label="Guarantor Name" className="inputBox" onChange={onChange} onKeyPress={handleKeyPress} />
                        </Col>
                    }

                    <div className="container searchWrapp ml5*">
                        <button onClick={searchRequest} className="searchBtn" onKeyPress={handleKeyPress} >
                            <span>Click to Search </span>
                            <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                variant="light"
                                aria-hidden="true"
                                hidden={progress ? false : true}
                            />
                        </button>
                        <FormControlLabel
                            control={<Checkbox name="checkedA" />}
                            label="Advanced Search"
                            checked={selectedValue}
                            onChange={handleChange}
                            className="termText"
                        />
                    </div>
                </Row>

            </div>

           <div className="container mt-5">

                {searchCount > 0 ? 

                <Paper style={{display:resultShow ? 'block':'none'}}>
                    <TableContainer className="">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                                    <TableRow key={columns.id} >
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.copyDisable}>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover key={row.defaulter_id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                
                                                return (
                                                  <TableCell
                                                    key={column.defaulter_id}
                                                    align={column.align}
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {row[column.id] === null
                                                      ? "Not Mentioned"
                                                      : ""}
                                                    {/* {row[column.id] == "NULL"
                                                      ? "Not Mentioned"
                                                      : ""} */}
                                                    {column.id === "name"
                                                      ? value
                                                      : ""}
                                                    {column.id === "bank_name"
                                                      ? value
                                                      : ""}
                                                    {column.id ===
                                                    "is_defaulter"
                                                      ? value === "0"
                                                        ? "No"
                                                        : "YES"
                                                      : ""}
                                                    {column.id === "pan_no" ||
                                                    column.id === "pan_no"
                                                      ? row[column.id] == "NULL"
                                                        ? "Not Mentioned"
                                                        : value != null
                                                        ? secureVoter(value)
                                                        : ""
                                                      : ""}
                                                    {column.id === "adhar_no" ||
                                                    column.id === "adhar_no"
                                                      ? row[column.id] == "NULL"
                                                        ? "Not Mentioned"
                                                        : value != null
                                                        ? value.replace(
                                                            /\d{4}$/,
                                                            "XXXX"
                                                          )
                                                        : ""
                                                      : ""}
                                                    {column.id === "voter_no" ||
                                                    column.id === "voter_no"
                                                      ? row[column.id] == "NULL"
                                                        ? "Not Mentioned"
                                                        : value != null
                                                        ? secureVoter(value)
                                                        : ""
                                                      : ""}
                                                    {column.id ===
                                                    "guarantor1_name"
                                                      ? row[column.id] == "NULL"
                                                        ? "Not Mentioned"
                                                        : value
                                                      : ""}
                                                    {column.id ===
                                                    "guarantor2_name"
                                                      ? row[column.id] == "NULL"
                                                        ? "Not Mentioned"
                                                        : value
                                                      : ""}
                                                    {column.id == "action" ? (
                                                      <div
                                                        className="viewDetails"
                                                        onClick={() =>
                                                          handleOpen(row)
                                                        }
                                                      >
                                                        {" "}
                                                        View{" "}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                   
                    : searchCount ==0 ? "No  Customer  Credit Data Found":'' }
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={[classes.paper]} >
                            <div className={classes.modalhead}>
                            <h4>Society Portal Loan Credit Details Report</h4>
                                {/* <h4> {modalData.defaulter_id} </h4> */}
                                
                                <div className={classes.copyDisable}>
                                    
                                    <Button type="button" onClick={handleDownload}> <FiPrinter /> Download Report
                                    <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            variant="light"
                                            aria-hidden="true"
                                            hidden={progressd ? false : true}
                                        />
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button type="button" onClick={handleClose}> <FiXCircle /> Close</Button>
                                </div>
                            </div>
                            <hr/>
                            <div className="container-fluid modalWrapp">

                                <h4 style={{textAlign:'center'}}>CREDIT BUREAU INFORMATION  CBI</h4>
                                <hr/>
                                <Row>
                                    <Col md={4}>Average Credit Score <h2 style={{ display: 'contents' }}>{modalData.credit_score}</h2></Col>
                                    <Col md={4}>Credit RISK <h2 style={{ display: 'contents',textTransform:'uppercase' }}>{modalData.risk_description}</h2></Col>
                                    <Col md={4}>Credit description <h2 style={{ display: 'contents', textTransform: 'uppercase' }}>{modalData.credit_score_description}</h2></Col>
                                </Row>
                                <hr/>

                                <div>
                                    <h6>Inquiry Details</h6>

                                </div>
                               
                               
                                       {/* <Table className="modalTable">
                                    <TableBody key={modalData[columnsModal.defaulter_id]}> */}
                                               {
                                                   columnsModal.map((column)=>{
                                                       const value = modalData[column.id]
                                                       return(
                                                           <Row key={modalData.defaulter_id}>
                                                               <Col align="left" className="modalCell"> {column.label} </Col>
                                                               <Col>
                                                                  
                                                                   {modalData[column.id] == "NULL" || modalData[column.id] == null ? "Not Mentioned" : column.id === 'is_defaulter' ? modalData[column.id] === "0" ? "No" : "YES" : modalData[column.id]} 
                                                               </Col>
                                                           </Row>
                                                       )
                                                   })
                                               }
                                              
                                           {/* </TableBody>
                                       </Table> */}
                                   
                                        {/* <Table className="modalTable">
                                            <TableBody> */}
                                                {
                                                    columnsModal2.map((column) => {
                                                        return (
                                                            <Row key={modalData[column.defaulter_id]}>
                                                                <Col align="left" className="modalCell" key={column.label}> {column.label} </Col>
                                                                <Col>
                                                                    {modalData[column.id] == "NULL" ? "Not Mentioned" : column.id === 'is_npa' || column.id === 'is_secured' ? modalData[column.id] === "0" ? "No" : "YES" : modalData[column.id] == "standard" ? "Standard Assets" : modalData[column.id] == "sub_standard" ? "Sub Standard" : modalData[column.id] == "doubtful_a" ? "DoubtFul A" : modalData[column.id] == "doubtful_b" ? "DoubtFul B" : modalData[column.id] == "doubtful_c" ? "DoubtFul C " : modalData[column.id] == "loss_asset" ? "Loss Asset" : modalData[column.id]}
                                                                    
                                                                  
                                                              </Col>


                                                            </Row>
                                                        )
                                                    })
                                                }
                                                
                                                <Row>
                                                    <Col colSpan="2" align="left" className="modalCell"> 
                                                        <hr />
                                                        <b>Declaration!</b> We declare that the information provided by Society/Bank on the above form is true and correct to the best of our knowledge.
                                                    </Col>
                                                </Row>
                                            {/* </TableBody>
                                        </Table> */}
                                 
                            </div>
                            
                        </div>
                    </Fade>
                </Modal>


           </div>


        </div>
    )
}
export default Index

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70%',
        margin: 'auto',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height:'100%',
        userSelect: 'none'
    },
    modalhead:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    copyDisable:{
        userSelect: 'none'
    }
}));
