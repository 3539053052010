import React, { Component } from 'react'
import {Link, } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import { Container, Row, Col, Spinner} from "react-bootstrap";
import {  } from "./index.css";
import { Button, TextField ,} from '@material-ui/core';
import loginbg from "../../assets/images/loginbg.png";
import { PostData } from "../services/PostData";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            username:"",
            password:"",
            registration_no: "",
            error:"",
            show:false,
            progress:false
        }
    }

    notify = () => toast(this.state.error);
   componentDidMount(){
       if (localStorage.getItem("token")){
            this.props.history.push('/dashboard')
           return;
       }
   }

    async validateDetails() {
        // console.log("inside validateDetails....");
        var toastId = null;
        this.notifyDetails = () => this.toastId = toast.error("Please fill the details properly", { autoClose: 3000 });
        this.notifyEmail = () => this.toastId = toast.error("Please enter valid Email", { autoClose: 3000 });
        this.notifyPassword = () => this.toastId = toast.error("Please enter valid password", { autoClose: 3000 });
        this.notifyCustome = () => this.toastId = toast.error("Please enter valid registration No", { autoClose: 3000 });
        // console.log("this.state.patientName", this.state.patientName);
        if (this.state.username === "" || this.state.password === "" || this.state.registration_no === "") {
            // console.log("inside blank");
            this.setState({ progress: false })
            return this.notifyDetails();
        } else if (this.state.username != "" && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.username))) {
            this.setState({ progress: false })
            return this.notifyEmail();
        } else if (this.state.password.length < 6) {
            this.setState({ progress: false })
            return this.notifyPassword();
        } 
        else if (this.state.registration_no.toString().length < 6) {
            this.setState({ progress: false })
            return this.notifyCustome();
        } 
        else {
            this.sendData()
        }
    }  

    sendData =()=>{
        PostData('login', this.state).then((result) => {
            let responseJSON = result;
            let token;
            let user_id;
            if (responseJSON.status === "SUCCESS") {
                token = responseJSON.token;
                user_id = responseJSON.user_details.id;
                this.notify()
                this.setState({ progress: false })
            } else {

                this.setState({ error: responseJSON.message, show: true })
                this.notify()
                this.setState({ progress: false })
            }
            // console.log(responseJSON);
            if (responseJSON.token) {
                localStorage.setItem("token", token);
                localStorage.setItem("user_id", user_id);
                // console.log("user_details", responseJSON);
                this.props.history.push('/dashboard');
            } else {

                this.setState({ error: responseJSON.message, show: true })
                this.setState({ progress: false })
            }
        })
    }
    
    login=()=>{
        this.setState({ progress: true })
        this.validateDetails()
    }
     handleKeyPress = event => {
        if (event.key == 'Enter') {
            this.setState({ progress: true })
            this.validateDetails()
        }
    };
    onChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
        // console.log(this.state);
    }
    render() {
          const { show,setShow}=this.state.show
        return (
          
            <Container fluid="true">
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Row className="h100">
                    <Col xs={12} sm={12} md={5} className="h100 colOneWrapper" >
                        <div className="primaryColor headingLogin"> Login to your account</div>
                        <div className="lightColor tagLine">Easy to access portal</div>
                       
                       
                        <div className="loginWrap" >
                            <TextField  name="username" label="Email Address (Username)"  className="inputBox" onChange={this.onChange}  />
                            <TextField type="password" name="password" label="Password" className="inputBox" onChange={this.onChange} />
                            <TextField type="number" name="registration_no" label="Registration No." className="inputBox" onChange={this.onChange} onKeyPress={this.handleKeyPress} />
                            <small style={{ color: 'red', display: show ? 'none' :'block'}}>{this.state.error}</small>
                            <Button
                                variant="contained"
                                // color="primaryBgColor"
                                className="primaryBgColor loginButton"
                                //endIcon={<Icon>send</Icon>}
                                onClick={this.login}>
                                <span> Login to procced</span>

                                 <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    variant="light"
                                    aria-hidden="true"
                                    hidden={this.state.progress ? false : true}
                                />
                            </Button>
                        </div> 
                        <div style={{ margin: '20px 0' }}>
                            <div className="lightColor"> Don’t have an account? <Link to="/register" > Create an account</Link> </div>
                        </div>
                        <div className="footerWrap">
                            <div className="lightColor">Copyright@Amigoestech2020</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={7} className="colTwoWrapper h100" style={{ backgroundImage: 'url(' + loginbg + ')', backgroundPosition: "center",backgroundSize: "cover" }}>
                    </Col>
                </Row>
           </Container>
        )
    }
}

export default Login

