import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Spinner} from "react-bootstrap";
import { } from "./index.css";
import { Button, TextField, } from '@material-ui/core';
import loginbg from "../../assets/images/loginbg.png";
import { PostData } from "../services/PostData";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: "",
            registration_no: "",
            full_name: "",
            bank_society_name: "",
            email: "",
            contact_no: "",
            branch_name: "",
            branch_code: "",
            bank_address: "",
            error: "",
            show: false,
            progress:false
        }}

        notify = () => toast(this.state.error);

    async validateDetails() {
       
        // console.log("inside validateDetails....");
        var toastId = null;
        this.notifyDetails = () => this.toastId = toast.error("Please fill all details properly", { autoClose: 3000 });
        this.notifyEmail = () => this.toastId = toast.error("Please enter valid Email", { autoClose: 3000 });
        this.notifyMobile = () => this.toastId = toast.error("Please enter valid mobile number ", { autoClose: 3000 });
        this.notifyPassword = () => this.toastId = toast.error("The password must be at least 6 characters", { autoClose: 3000 });
        this.notifyCustome = (customenotify) => this.toastId = toast.error(customenotify, { autoClose: 3000 });

// 
        if (this.state.full_name == "" || this.state.contact_no == "" || this.state.email === "" || this.state.password === "" || this.state.bank_society_name == "" || this.state.registration_no === "" ) {
            this.setState({progress: false })
            return this.notifyDetails();
        } else if (!(/^[a-zA-Z\s.]*$/g.test(this.state.full_name))){
            this.setState({ progress: false })
            return this.notifyCustome("Full name must be charachters");
        }else if (this.state.full_name.length < 6) {
            this.setState({ progress: false })
            return this.notifyCustome("Full name must be at least 6 charachters");
        } else if (this.state.contact_no.toString().length != 10) {
            this.setState({ progress: false })
            return this.notifyMobile();
        }else if (this.state.email != "" && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
            this.setState({ progress: false })
            return this.notifyEmail();
        }  else if (this.state.password.length < 6) {
            this.setState({ progress: false })
            return this.notifyPassword();
        } else if (this.state.bank_society_name.length < 3) {
            this.setState({ progress: false })
            return this.notifyCustome("Enter Bank/Organizations name at least 3 character");
        }  else if (this.state.registration_no.toString().length < 6) {
            this.setState({ progress: false })
            return this.notifyCustome("Registration No. length should be atleast 6 digit");
        } 
        else{
            PostData('register', this.state).then((result) => {
                let responseJSON = result;
                // console.log(responseJSON);
                // console.log("reg", responseJSON.token);
                if (responseJSON.status === "SUCCESS") {
                    this.setState({ progress: false })
                    this.setState({ error: responseJSON.message, show: true })
                    this.notify()
                    this.props.history.push('/login');
                } else {
                    this.setState({ progress: false })
                    this.setState({ error: responseJSON.message, show: true })
                    this.notify()
                   
                }
            })
        }
    }

        register = () => {
            this.setState({ progress: true })
            this.validateDetails()
            
            
        }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        // console.log(this.state);
    }
    render() {
        const { show, setShow } = this.state.show
        return (
            <Container fluid="true">
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Row className="h100">
                    <Col xs={12} sm={12} md={5} className="h100 colOneWrapper" >
                        <div className="primaryColor headingLogin"> Register with us</div>
                        <div className="lightColor tagLine">One Step to Connect </div>
                       
                            <div className="registerWrap" >
                                <TextField name="full_name" label="Full Name" className="inputBox" onChange={this.onChange} />
                               <Row className="fromGroup">
                                <Col xs={12} sm={6} md={6} >
                                    <TextField type="number" name="contact_no" label="Contact No." className="inputBox" onChange={this.onChange} maxLength={10}  />
                                </Col>
                                <Col xs={12} sm={6} md={6} >
                                    <TextField name="email" label="Email Address" className="inputBox" onChange={this.onChange} />
                                </Col>
                               </Row>
                                 <TextField type="password" name="password" label="Password (eg. Abc@123 )" className="inputBox" onChange={this.onChange} />
                               
                            <TextField name="bank_society_name" label="Organizations Name" className="inputBox" onChange={this.onChange} />
                                <TextField type="number" name="registration_no" label="Registration No." className="inputBox" onChange={this.onChange} />
                                <Row className="fromGroup">
                                <Col xs={12} sm={6} md={6} >
                                <TextField name="branch_name" label="Branch Name" className="inputBox" onChange={this.onChange} />
                                </Col>
                                <Col xs={12} sm={6} md={6} >
                                <TextField type="number" name="branch_code" label="Branch Code" className="inputBox" onChange={this.onChange} />
                                </Col>
                                </Row>
                                <TextField name="bank_address" label="Branch Address" className="inputBox" onChange={this.onChange} />

                                 <small style={{ color: 'red', display: show ? 'none' : 'block' }}>{this.state.error}</small>
                           
                                <Button
                                    variant="contained"
                                    // color="primaryBgColor"
                                    className="primaryBgColor loginButton"
                                    //endIcon={<Icon>send</Icon>}
                                    onClick={this.register}>
                                    <span> Register here</span>
                                     <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    variant="light"
                                    aria-hidden="true"
                                    hidden={this.state.progress ? false : true}
                                />
                            </Button>
                            </div>
                        
                        <div style={{ margin: '20px 0'}}>
                            <div className="lightColor"> Already have an account ?  <Link to="/" > Login to account</Link> </div>
                        </div>
                        <div className="footerWrap">
                            <div className="lightColor">Copyright@Amigoestech2020</div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={7} className="colTwoWrapper h100" style={{ backgroundImage: 'url(' + loginbg + ')', backgroundPosition: "center", backgroundSize: "cover" }}>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Register
