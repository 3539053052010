import React, {Component} from 'react'
 import { Spinner} from "react-bootstrap";
// import { Card, CardContent, Typography } from '@material-ui/core';
// import { FiUploadCloud } from "react-icons/fi";
import './upload.css';
import {UploadData} from '../services/UploadData';
import Dropzone from 'react-dropzone'
import { ToastContainer, toast } from 'react-toastify';




class index extends Component  {

    constructor(props){
        super(props)
        this.state = {

            // Initially, no file is selected 
            defaulter_file:"",
            token:null,
            user_id:null,
            fileName:"",
            fileuploaded:false,
            message:"",
            progress:false,
            created_rows:0,
            updated_rows:0
        }; 
        // var token = sessionStorage.getItem('token');
        // var user_id = sessionStorage.getItem('user_id')



    }
    notifyError = () => this.toastId = toast.error("Please upload file properly", { autoClose: 3000 });
    notify = () => this.toastId = toast.error(this.state.message, { autoClose: 3000 });

    componentDidMount() {
        
       
    }

    onFileChange = (event) => {
        // Update the state 
        var file =event.target.files[0]
        this.setState({ token: localStorage.getItem('token'), user_id: localStorage.getItem('user_id') })
        // const formdata = new FormData(); 
        // formdata.append("token", this.state.token);
        // formdata.append("user_id", this.state.user_id);
        // formdata.append("defaulter_file", file);
        this.setState({ defaulter_file: file });
    };

    onDrop = async(acceptedFiles)=> {
        acceptedFiles.forEach(file => {
            this.setState({
                defaulter_file: file,
                fileName: file.name,
                fileuploaded: true,
                // filepath: URL.createObjectURL(file)
                token: localStorage.getItem('token'), 
                user_id: localStorage.getItem('user_id') 
            });
        })
    }

    onFileUpload = () => {

        if(this.state.defaulter_file === ""){
            this.notifyError();
        }else{
            // console.log("formdata", this.state);

            // console.log(this.state.defaulter_file);
            this.setState({progress:true})
            UploadData("admin/uploaddefaulters", this.state)
              .then((result) => {
                let responseJSON = result;
                // console.log("userdetails", responseJSON);
                this.setState({
                  message: responseJSON.message,
                  defaulter_file: "",
                  fileName: "",
                  created_rows: responseJSON.data.created_rows,
                  updated_rows: responseJSON.data.updated_rows
                });
                // this.setState({})
                this.setState({ progress: false });
                this.notify();
              })
              .catch((error) => {
                // console.log("error msg", error);
              });
        }
        

    };
render(){
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <div className="searchHeader">
                {/* <Button className="iconWrap primaryBgColor"> <FiUploadCloud className="searchIcon" /> </Button> */}
                <div className="titleWrapp">
                    <div className="searchTitle primaryColor"> Upload Loan Defaulters Data</div>
                    <div className="searchSubTitle lightColor cardTitle" color="textSecondary" > find the loan defaulters here </div>

                </div>

            </div>

            <div className="container-fluid mt-5">
                <Dropzone onDrop={this.onDrop} accept='.xlsx, .xls, .csv' multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="container">
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <p style={{ textAlign: 'center' }}> Click here Upload Excel File or Drag 'n' drop excel file here</p>

                                <aside>
                                    <p>{this.state.fileName}</p>
                                </aside>
                            </div>

                        </section>
                    )}
                </Dropzone>
                {/* <input type="file" name="defaulter_file" accept=".xlsx, .xls, .csv" onChange={this.onFileChange} /> */}
                <div className="uploadBtnWrap container">
                    <button onClick={this.onFileUpload} className="uploadBtn">
                        <span> Upload Data</span>
                         <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            variant="light"
                            aria-hidden="true"
                            hidden={this.state.progress ? false : true}
                        />
                    </button> 
                </div>
               
                <div className="uploadCount">
                    <div>Created rows : {this.state.created_rows}</div>
                    <div>Updated rows : {this.state.updated_rows} </div>
                </div>
              
            </div>
        </div>
    )
    } 
}
export default index