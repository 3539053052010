import environment from "../environment/environment";

export async function UploadData(type, userData) {
    let BaseUrl = environment.REACT_APP_API_URL + "/api/";
    // let BaseUrl = "/api/";
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", 'Bearer '+userData.token);

    let formdata = new FormData();
    formdata.append('token', userData.token);
    formdata.append('user_id', userData.user_id);
    formdata.append('defaulter_file', userData.defaulter_file, userData.fileName);



    // console.log("formdata", userData.defaulter_file.name);

    return new Promise((resolver, reject) => {
        fetch(BaseUrl + type, {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolver(responseJson);
            })
            .catch((error) => {
                reject(error);
                console.log("error msg", error);
            })

    });
}